<script>
  import { get, call } from 'vuex-pathify'
  import { ChoiceAutoSelectModalTypes } from '@/store/customize'

  export default {
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        ChoiceAutoSelectModalTypes,
        modalTypes: ['chooseForMe', 'mystery'],
      }
    },
    computed: {
      ...get('customize', [
        'choiceChooseForMeVariant',
        'choiceAutoSelectModalType',
        'mysteryChoiceSkuBase',
        'mysteryChoiceImage',
      ]),
      chooseForMeVariantImg() {
        return this.choiceChooseForMeVariant().previewCardImages[0]
      },
      modalHorizonalPadding() {
        return this.choiceAutoSelectModalType === this.ChoiceAutoSelectModalTypes.MYSTERY
      },
      modalVerticalPadding() {
        return this.choiceAutoSelectModalType === this.ChoiceAutoSelectModalTypes.MYSTERY
      },
      modalClasses() {
        if (this.choiceAutoSelectModalType === this.ChoiceAutoSelectModalTypes.MYSTERY) {
          return ''
        }
        return undefined
      },
    },
    methods: {
      ...call('customize', ['setChoiceAutoSelectModalVisible']),
    },
  }
</script>

<template>
  <Portal v-if="choiceAutoSelectModalType" to="modal">
    <BaseModal
      :open="open"
      size="xs"
      bg-color="dawn"
      :no-horizontal-padding="modalHorizonalPadding"
      :no-vertical-padding="modalVerticalPadding"
      :class="modalClasses"
      @dismiss="$emit('dismiss')"
    >
      <template v-if="choiceAutoSelectModalType === ChoiceAutoSelectModalTypes.CHOOSE_FOR_ME">
        <BaseImage
          :src="chooseForMeVariantImg"
          alt=""
          background-transparent
          responsive="xs"
          class="mx-auto -mb-3"
          style="max-width: 156px"
        />
        <h1 class="font-heading font-medium text-5xl text-center leading-close mb-1">
          Choose for me
        </h1>
        <p class="text-center leading-snug-tight mx-auto mb-8" style="max-width: 266px">
          Deciding is difficult, we know. That’s why you can opt for us to pick a product in this
          category that we think you’ll love. <span class="inline-block">Easy peasy!</span>
        </p>
        <BaseButton class="pb-2 max-w-46 mx-auto" @click="setChoiceAutoSelectModalVisible(false)">
          Okay
        </BaseButton>
      </template>
      <template v-if="choiceAutoSelectModalType === ChoiceAutoSelectModalTypes.MYSTERY">
        <div class="bg-spring">
          <BaseImage
            :src="mysteryChoiceImage"
            alt=""
            background-transparent
            responsive="xs"
            class="mx-auto py-4"
            style="max-width: 116px"
          />
        </div>
        <div class="pt-6 pb-8 px-17px">
          <h1 class="font-heading font-medium text-5xl text-center leading-close mb-1">
            Mystery Product
          </h1>
          <p class="text-center leading-snug-tight max-w-88 mx-auto mb-22px">
            Up for a fun surprise? We’ll include a totally different product to make your box
            unique. *Note: This may be an item from a past box, an Add-On Market, or a totally new
            product that's never been seen before!
          </p>
          <BaseButton class="pb-2 max-w-46 mx-auto" @click="setChoiceAutoSelectModalVisible(false)">
            Okay
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </Portal>
</template>

<!-- eslint-disable vue/script-indent -->
<script>
  import { get, call } from 'vuex-pathify'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import screen from '@/helpers/screen'
  import CustomizeChoiceAutoSelectModal from '@/components/customize/CustomizeChoiceAutoSelectModal'
  import ShippingModal from '@/components/marketplace/ShippingModal'
  import SubscribeProgressIndicator from '@/components/subscribe/SubscribeProgressIndicator'

  const guardMixin = createGuardMixin(
    async ({ store, route, redirect }) => {
      const ensureFreshConfig = store.dispatch('config/ensureFresh')
      await store.dispatch('config/loadOpenBoxes')
      const ensureFreshCustomizeData = store.dispatch('customize/ensureFresh')
      const ensureFreshProfileData = store.dispatch('account/fetchProfile')
      if ((await store.dispatch('account/loggedInAsync')) === false) {
        return redirect({ name: 'AccountLogin' })
      }
      await Promise.all([ensureFreshConfig, ensureFreshCustomizeData, ensureFreshProfileData])
      // Make sure we have a selected subscription if we're in any view except CustomizeStart
      if (route.name !== 'CustomizeStart' && route.name !== 'CustomizeDetails') {
        /* customize/selectedSubscription relies on `route` (to get a param in the URL),
         * which unfortunately doesn't get updated in Vuex until all navigation guards have resolved...
         * so we temporarily override it with a mock object based on the `to` route provided by the guard.
         * Seems sketchy, but it works well.
         */
        store.set('customize/mockRoute', route)
        const selectedSubscription = store.get('customize/selectedSubscription')

        store.set('customize/mockRoute', null)
        if (!selectedSubscription) {
          return redirect({ name: 'CustomizeStart' })
        }
      }
    },
    ['loggedIn']
  )

  export default {
    components: {
      CustomizeChoiceAutoSelectModal,
      SubscribeProgressIndicator,
      ShippingModal,
    },
    mixins: [guardMixin],
    data() {
      return {
        openAvatarMenu: false,
        summaryRoutes: [
          'CustomizeSummary',
          'CustomizeSummaryDetails',
          'CustomizeOrderSummary',
          'CustomizeOrderSummaryDetails',
        ],
        shippingModalVisible: false,
      }
    },
    computed: {
      screen,
      ...get('general', ['navigationRoute']),
      ...get('account', ['avatarUrl', 'loggedIn', 'findShipmentByOrderId']),
      ...get('customize', [
        'selectedSubscription',
        'currentChoice',
        'choiceCount',
        'hasMoreChoicesComing',
        'skipFirstScreen',
        'isWaitlistAndIsQuarterly',
        'isSelectedSubscriptionCustomizable',
        'shippingTimelineModalVisible',
        'choiceAutoSelectModalVisible',
      ]),
      ...get('choicePlusCart', ['cartQuantity']),
      ...get('config', ['currentSeasonCoreBox', 'currentSeason', 'currentBox', 'boxesById']),
      ...get('prismic', ['customizeReviewPageData']),
      bodyCopy() {
        return this.customizeReviewPageData
          ? this.customizeReviewPageData.find((data) => data.type === 'body').description_copy[0]
              .text
          : ''
      },
      timelineCopy() {
        return this.customizeReviewPageData
          ? this.customizeReviewPageData.find((data) => data.type === 'timeline')
              .description_copy[0].text
          : ''
      },
      isInSubscribeFlow() {
        return this.$route.path.includes('subscribe')
      },
      showProgressIndicator() {
        return (
          this.isInSubscribeFlow &&
          !this.$route.name.includes('Details') &&
          this.$route.name !== 'CustomizeUpgrade' &&
          this.$route.name !== 'CustomizeChoicePlusThankYou' &&
          !this.isWaitlistAndIsQuarterly
        )
      },
      finishingChoices() {
        return ['CustomizeComplete', 'CustomizeSummary'].includes(this.navigationRoute?.name)
      },
      headerTitle() {
        return this.$route.name === 'ChoicePlusCart' ? 'Choice+ Checkout' : 'My Choices'
      },
      routerViewKey() {
        if (
          this.$route.name === 'CustomizeChoice' ||
          this.$route.name === 'CustomizeChoiceDetails'
        ) {
          return `CustomizeChoice-${this.currentChoice}`
        }
        if (this.$route.name === 'CustomizeSummaryDetails') {
          return 'CustomizeSummary'
        }
        if (this.$route.name === 'CustomizeOrderSummaryDetails') {
          return 'CustomizeOrderSummary'
        }
        return this.$route.path
      },
      orderId() {
        return this.$route.params.orderId
      },
      isReviewAndConfirm() {
        return this.isSelectedSubscriptionCustomizable && !this.orderId
      },
      showHeader() {
        const routes = [
          'CustomizeChoice',
          'CustomizeChoiceDetails',
          'CustomizeBonuses',
          ...this.summaryRoutes,
        ]
        return routes.includes(this.$route.name)
      },
      headerMaxWidth() {
        return this.summaryRoutes.includes(this.$route.name) ? 'lg' : 'none'
      },
      currentSeasonCoreBoxTitle() {
        const boxTitle = this.currentSeasonCoreBox?.title
        const truncatedYear = this.currentSeasonCoreBox?.year.toString().slice(-2)
        if (boxTitle && truncatedYear) {
          return `${boxTitle} '${truncatedYear}`
        } else {
          return ''
        }
      },
      timelineModalSlug() {
        return `${this.currentSeason?.toLowerCase()}-boxes-choice-orders`
      },
      shipment() {
        return this.orderId ? this.findShipmentByOrderId(this.orderId) : undefined
      },
      isShipmentCurrentBox() {
        return this.orderId ? this.shipment?.boxId === this.currentBox?.id : false
      },
      isTimelineLinkVisible() {
        if (this.isReviewAndConfirm) {
          return true
        } else if (this.isShipmentCurrentBox && !this.shipment?.trackingUrl) {
          return true
        } else {
          return false
        }
      },
      currentBoxTitle() {
        if (this.currentBox?.label) {
          return this.currentBox?.label
        } else {
          return this.currentSeasonCoreBoxTitle
        }
      },
      summaryBoxTitle() {
        return this.boxesById[this.shipment?.boxId]?.label
      },
      boxTitleToDisplay() {
        return this.isReviewAndConfirm ? this.currentBoxTitle : this.summaryBoxTitle
      },
    },
    async created() {
      window.addEventListener('keydown', this.handleKeyDown)
      window.addEventListener('click', this.handleClick)
      this.loadCustomizeReviewPageData()
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
      window.removeEventListener('click', this.handleClick)
    },
    methods: {
      ...call('customize', ['setChoiceAutoSelectModalVisible']),
      ...call('prismic', ['loadCustomizeReviewPageData']),
      handleKeyDown($event) {
        // If the escape key was pressed and the menu is open, dismiss it
        if ($event.keyCode === 27 && this.openAvatarMenu) {
          this.openAvatarMenu = false
        }
      },
      handleClick($event) {
        if (!this.$refs.menuRef?.contains($event.target)) {
          this.openAvatarMenu = false
        }
      },
      goBack() {
        this.$navigate({ name: 'AccountProfileSubscriptions' })
      },
    },
  }
</script>

<template>
  <div class="pb-14 overflow-hidden">
    <SubscribeProgressIndicator v-if="showProgressIndicator" />
    <div
      v-if="summaryRoutes.includes($route.name) && !isReviewAndConfirm"
      class="back-btn-container px-6 sm:px-9 pt-4 sm:pt-8 mx-auto"
    >
      <div class="xs:max-w-120 lg:max-w-full w-full mx-auto">
        <BaseLinkStyled
          color="black"
          font-size="xs"
          icon-position="left"
          :icon-size="3"
          @click="goBack"
        >
          <template v-slot:icon>
            <IconArrowLeft />
          </template>
          <template v-slot>Back</template>
        </BaseLinkStyled>
      </div>
    </div>
    <div v-if="showHeader">
      <BaseContainer :max-width="headerMaxWidth">
        <div class="flex justify-center items-center pt-17px lg:pt-6 pb-34px lg:pb-6">
          <div>
            <h1
              v-if="$route.name === 'CustomizeChoice' || $route.name === 'CustomizeChoiceDetails'"
              class="font-heading text-4xl"
              >Customization #{{ currentChoice }}</h1
            >
            <div
              v-if="$route.name === 'CustomizeChoice' || $route.name === 'CustomizeChoiceDetails'"
              class="absolute inset-x-0 top-0 mt-15 flex justify-center"
            >
              <div class="w-32 h-1 flex-none rounded-full bg-dawn-dk3 overflow-hidden">
                <div
                  class="absolute inset-0 bg-primary transition-width duration-600"
                  :style="{
                    width: `${finishingChoices ? 100 : (currentChoice / choiceCount) * 100}%`,
                  }"
                />
              </div>
              <span class="ml-2 -mt-6px font-medium text-3xs">
                {{ currentChoice }} of {{ choiceCount }}
              </span>
            </div>
            <!-- Also display in CustomizeSummaryDetails view to keep background DOM elements the same. -->
            <div v-if="summaryRoutes.includes($route.name)" class="text-center">
              <p v-if="boxTitleToDisplay" class="uppercase text-2xs">
                {{ boxTitleToDisplay }}
              </p>
              <h1 class="font-heading text-4xl leading-snug">
                {{ orderId ? 'Box Summary' : 'Review & Confirm' }}</h1
              >
              <div class="text-xs leading-snug-tight lg:pb-6">
                {{ bodyCopy }}
                <br />
                <template v-if="isTimelineLinkVisible">
                  <span v-if="!orderId">
                    {{ timelineCopy }}
                  </span>
                  <BaseLinkStyled
                    class="block cursor-pointer"
                    color="black"
                    @click="shippingModalVisible = true"
                  >
                    View fulfillment and shipping timelines.
                  </BaseLinkStyled>
                </template>
                <div v-else-if="shipment && shipment.trackingUrl">
                  <BaseLink
                    class="underline inline-block"
                    :href="shipment.trackingUrl"
                    target="_blank"
                  >
                    View Box Tracking Info
                  </BaseLink>
                </div>
              </div>
            </div>
            <div v-if="$route.name === 'CustomizeBonuses'" class="text-center">
              <h1 class="font-heading text-4xl">Box Upgrades</h1>
              <p class="text-xs md:text-base">
                Upgrade your box to include the premium
                <span class="inline-block">All Good Things</span> magazine and eco-shipping.
              </p>
            </div>
          </div>
        </div>
      </BaseContainer>
    </div>
    <ShippingModal
      :open="shippingModalVisible"
      type="choicePlus"
      @dismiss="shippingModalVisible = false"
    />
    <CustomizeChoiceAutoSelectModal
      :open="choiceAutoSelectModalVisible"
      @dismiss="setChoiceAutoSelectModalVisible(false)"
    />
    <div>
      <!-- Using a key to force reinitializing the `CustomizeChoice` view between choices. -->
      <RouterView :key="routerViewKey" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  .back-btn-container {
    @screen lg {
      max-width: 944px;
    }
  }
  /* purgecss end ignore */
</style>
